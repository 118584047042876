<template>

    <Head>
        <Title>{{ title }}</Title>
        <Meta v-if="!isProduction" name="robots" content="noindex, nofollow" />
    </Head>
</template>
<script lang="ts" setup>
import type { InteriorHeroModel, HomepageModel, PageModel } from '~/models';
import { contentTypes } from '~/models/project/contentTypes';
import { truncateWords, stripHtmlTags } from '~/utils/string-helper';
const config = useRuntimeConfig()
const route = useRoute()
const pageStore = usePageStore()
const isProduction = config.public.isProduction
const title = ref<string>("")
const url = ref<string>("")
const description = ref<string>("")
const image = ref<string>("")
if (pageStore.page) {
    title.value = stripHtmlTags(pageStore.pageTitle)
}
else {
    title.value = "Not Found"
}

url.value = `${config.public.appHost}${route.path}`
if (pageStore.page) {
    switch (pageStore.page.system.type) {
        case contentTypes.homepage.codename: {
            const model = pageStore.page as HomepageModel
            description.value = model.elements.seoMetadataMetaDescription.value
            break
        }
        case contentTypes.page.codename: {
            const model = pageStore.page as PageModel
            description.value = model.elements.seoMetadataMetaDescription.value
            break
        }
        default:
    }
}

</script>