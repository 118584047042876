export const truncate = (text: string, length: number) => {
  return text.length > length ? text.substring(0, length) + '...' : text
}
export const truncateWords = (text: string, length: number) => {
  return text.split(' ').slice(0, length).join(' ') + '...'
}
export const getFirstParagraph = (text: string) => {
  return text.split('\n')[0]
}
export const stripHtmlTags = (html: string) => {
  return html.replace(/<[^>]*>?/gm, '')
}
